<template>
  <div class="pagination">
    <a href="javascript:;" @click="pageTurn(0)" class="first_last">首页</a>
    <a href="javascript:;" @click="pageTurn(paging.index - 1)" class="first_last"
      >上一页</a
    >
    <template v-for="i in sum">
      <a
        href="javascript:;"
        @click="pageTurn(i - 1)"
        :key="i"
        v-if="showText(i)"
        v-text="showText(i)"
        :class="i === paging.index + 1 ? 'active' : 'page'"
      >
      </a>
    </template>
    <a href="javascript:;" @click="pageTurn(paging.index + 1)" class="first_last"
      >下一页</a
    >
    <a href="javascript:;" @click="pageTurn(sum - 1)" class="first_last">尾页</a>
  </div>
</template>
<script>
export default {
  props: ["sumCount", "perCount"],
  data() {
    return {
      paging: {
        index: 0, // index记录当前页码
        count: 5, // count表示每页多少条记录
      },
    };
  },
  computed: {
    sum() {
      // sum表示共有多少页
      return Math.ceil(this.sumCount / this.perCount);
    },
  },
  methods: {
    // 页码显示（有省略号）
    showText(i) {
      const vm = this;
      const index = vm.paging.index;
      const sum = vm.sum;
      if (i < 3 || i > sum - 2) {
        // 前两个和最后两个始终显示
        return i;
      } else if (i <= index + 2 && i >= index) {
        // 当前页的前一页和后一页始终显示
        return i;
      } else if (i === index + 3 || i === index - 1) {
        // 当前页的前前页和后后页显示 ...
        return "...";
      } // 除此之外都不显示
      return false;
    },
    // 翻页
    pageTurn(i) {
      const vm = this;
      if (i < 0 || i >= vm.sum || i === vm.paging.index) {
        return;
      }
      vm.paging.index = i;
      // 告诉父组件翻页了，让父组件自己调用init
      vm.$emit("pageTurn", i, vm.perCount);
    },
  },
};
</script>
<style scoped>
.pagination {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination .first_last {
  line-height: 28px;
  height: 30px;
  border: 1px solid #D3D3D3;
  background-color: #fff;
  color: #898989;
  padding: 0 5px;
  margin: 0 5px;
}
.pagination .page {
  width: 30px;
  height: 30px;
  border: 1px solid #D3D3D3;
  line-height: 28px;
  text-align: center;
  color: #898989;
  margin: 0 5px;
  background-color: #fff;
}
.pagination a{
  border-radius: 4px;
}
.pagination a.active {
  width: 30px;
  height: 30px;
  border: 1px solid var(--normal);
  line-height: 28px;
  text-align: center;
  color: var(--normal);
  margin: 0 5px;
  background-color: var(--normal);
}
.pagination a:hover,
.pagination a.active {
  color: #fff;
  background-color: var(--normal);
  border-color: var(--normal);
}
</style>
