import {
    get,
    post,
} from './http.js';
export const apiPostLogin = p => post('v1/user/login', p); //登录
export const apiPostSend = p => post('v1/sms/ali/send', p); //发送短信
export const apiPostRegister = p => post('v1/user/regist', p); //注册
export const apiPostPassword = p => post('v1/user/forgotPwd', p); //更新密码
export const checkUserStatus = p => post('v1/user/checkUserStatus', p); //检查当前手机号是否存在
export const logOffAccount = p => post('v1/user/logOffAccount', p); //注销用户


export const apiIndexBanner = p => post('v1/language/queryContentVO', p); //获取首页轮播
export const apiUpload = p => post('v1/cdn/ali/upload', p); //上传头像
export const apiUserInfo = p => post('v1/user/query', p); //获取用户信息
export const apiGetIndex = p => post('v1/product/query', p); //首页商品列表
export const apiGoodsDetail = p => post('v1/product/queryVO', p); //商品详情
export const apiLanguage = p => post('v1/language/queryContentVO', p); //变量说明

export const apiCreateOrder = (p,h) => {return post('v1/trade/submitOrder', p,h);} //创建订单
export const myOrderList = p => post('v1/trade/queryVO', p)// 我的订单列表
export const apiUploadProof = p => post('v1/trade/uploadProof', p); //上传支付凭证
export const apiOrderDetail = p => post('v1/trade/queryVO', p); //订单详情
export const apiOrderupdate = p => post('v1/trade/update', p); //更改订单状态
export const apiOrderDelete = p => post('v1/trade/scopeDelete', p); //删除订单状态
export const apiCancelOrder = p => post('v1/trade/cancelOrder', p); //取消订单


// export const apiOrderDelete = p => post('v1/trade/delete', p); //删除订单状态
export const getCertificate = p => post('v1/certificate/query', p)// 获取 证书抬头
export const DelCertificate = p => post('v1/certificate/delete', p)// 删除 证书抬头 
// export const saveCertificate = p => post('v1/certificate/save', p)// 添加|编辑 证书抬头
export const saveCertificate = p => post('v1/certificate/create', p)// 添加|编辑 证书抬头

export const updateUserInfo = p => post('v1/user/update', p)// 编辑个人信息

export const editUserInfo = p => post('v1/user/save', p)// 编辑个人信息
export const apiGetAddress = p => post('v1/address/queryVO', p)// 我的收货地址
export const addressEdit = p => post('v1/address/save', p)// 添加或修改收货地址
export const addressDel = p => post('v1/address/delete', p)//删除收货地址

export const getInvoices = p => post('v1/trade/queryVO', p)//我的发票
export const userInvoices = p => post('v1/invoice/queryVO', p)//我的发票信息
// export const addInvoices = (p,h) => {return post('v1/invoice/saveBatch', p,h);} //维护我的发票信息
export const addInvoices = p => post('v1/invoice/save', p)
export const updateInvoiceMessages = p => post('v1/invoice/update', p)//更改我的发票信息
export const delInvoices = p => post('v1/invoice/delete', p)//删除发票信息

export const addTradeInvoice = (p,h) => {return post('v1/tradeInvoice/updateTradeInvoiceAndTrade', p,h);}//申请开票

export const apiQuestion = p => post('v1/question/queryVO', p)//常见问题|帮助
export const apiAboutUs = p => post('v1/aboutUs/queryVO', p)//关于我们
export const apiPostSetting = p => post('v1/setting/queryVO', p)//获取数字字典
export const sysCompanyInfo = p => post('v1/sysCompanyInfo/queryVO', p)//获取公司信息

export const apiGetSetting = p => post('v1/contractSetting/query', p)//获取合同参数

export const apiPdf = p => post('v1/tradeContract/generateContractPDF', p)//获取数字字典

export const createDraftContract = p => post('v1/trade/generateContract', p)//生成合同
export const browseContractPage = p => post('v1/qys/browseContractPage', p)//预览合同
export const downloadContractPDF = p => post('v1/qys/downloadContractPDF', p)//下载合同

export const tradeCertificate = p => post('v1/tradeCertificate/queryCertPdfParamByUser', p)//api模式开启证书列表
export const notApitradeCertificate = p => post('/v1/tradeCertificate/query', p)//非api模式证书

export const certPdfParam = p => post('v1/tradeCertificate/queryCertPdfParam', p)//获取证书参数
    
export const sendEmailCode = p => post('v1/email/sendEmailCode', p)//发送邮箱验证
export const uploadBase64 = p => post('v1/cdn/ali/uploadBase64', p)//上传base64的pdf
export const tradeCertificateUpdate = p => post('v1/tradeCertificate/update', p)//更改部分证书订单数据
export const tradeInvoiceDetail = p => post('v1/tradeInvoice/queryVO', p)//查询发票详情信息



