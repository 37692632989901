<template>
  <div class="header_fix">
    <div class="header flex-box">
      <a href="javascript:;" class="icon_logo" @click="jumpType(1)">
        <img src="../assets/static/icon/icon_logo.png"  class="icon_logo" alt="" />
      </a>
      <div class="flex-box navs fs16 col3">
        <a href="javascript:;" @click="jumpType(1)" :class="[navIndex == 1 ? 'active' : '']">首页</a>
        <a href="javascript:;" @click="jumpType(2)" :class="[navIndex == 2 ? 'active' : '']">输入邀请码</a>
        <a href="javascript:;" @click="jumpType(3)" :class="[navIndex == 3 ? 'active' : '']">我的订单</a>
        <a href="javascript:;" @click="jumpType(4)" :class="[navIndex == 4 ? 'active' : '']">我的证书</a>
        <a href="javascript:;" @click="jumpType(5)" :class="[navIndex == 5 ? 'active' : '']">个人中心</a>
        <a href="javascript:;" @click="jumpType(6)" :class="[navIndex == 6 ? 'active' : '']">帮助</a>
        <a href="javascript:;" @click="jumpType(7)" :class="[navIndex == 7 ? 'active' : '']">联系我们</a>
        <a href="javascript:;" @click="jumpType(8)" :class="[navIndex == 8 ? 'active' : '']">关于我们</a>
        <a href="http://www.climatebridge.com" target="_blank"  @click="">环保桥官网</a>
      </div>
      <template v-if="curPath !='/login'">
        <template v-if="userinfo == null && !is_login">
        <a href="javascript:;" @click="jumpLogin(1)" class="login_btn fs14 ml30">登录</a>
        <a href="javascript:;" @click="jumpLogin(2)" class="register_btn fs14 ml20">注册</a>
      </template>
      <div class="user_box pl10 flex-box" v-else @click="showQuitPop">
        <div v-if="userinfo.avatar !=null && userinfo.avatar !=''">
          <img :src="oss + userinfo.avatar" class="avatar" alt="" />
        </div>
        <div v-else>
          <img :src="avatar" class="avatar" alt="" />
        </div>
        <div class="fs16 pl10 user_name  flex-box"><span class="single-line">{{userinfo.nick_name?userinfo.nick_name: userinfo.tel }}</span></div>
        <div class="quit_box flex-box flex-center fs16" :class="showQuit ? 'show' : ''" @click="quitLogin">
          退出登录
        </div>
      </div>
      </template>
      <template v-else>
        <a href="javascript:;" class="login_btn2 fs14 ml30"></a>
        <a href="javascript:;" class="register_btn2 fs14 ml20"></a>
      </template>
    </div>

    <Popup :visible="codePop" :confirmText="false" :cancleText="false" @close="closePop">
      <div class="code_wrap">
        <img src="../assets/static/icon/icon_close.png" class="icon_close" @click="closePop" alt="" />
        <div class="fs20 pb30">输入商品邀请码</div>
        <div class="flex-box pb60">
          <div class="fs18 col9">商品邀请码：</div>
          <input type="text" v-model="invitationCode" class="code_input" />
        </div>
        <div class="flex-box flex-end cancel_sure">
          <div @click="closePop">取消</div>
          <div @click="invitation">确定</div>
        </div>
      </div>
    </Popup>
  </div>
</template>

<script>
import { Loading, Message, MessageBox } from "element-ui"; // 消息提示
import "element-ui/lib/theme-chalk/index.css";
import { apiEmsSend, apiGetPlan, ForgetPassword, Upload } from "../request/api.js";
import cookie from "vue-cookies";
import axios from "axios";
export default {
  name: "Header",
  data() {
    return {
      codePop: false,
      oss: this.global.uploadOss,
      is_login: false,
      showQuit: false,
      navIndex: 0,
      userinfo: null,
      avatar: require("../assets/static/images/avatar.png"),
      invitationCode: '',
      curPath:''
    };
  },

  created() {
    if (window.localStorage.getItem("Header")) {
      this.plans = JSON.parse(window.localStorage.getItem("Header"));
    }
    var token = cookie.get("huanbaoqiao_token");
    if (!token) {
      this.is_login = false;
    } else {
      this.is_login = true;
      let userinfo = cookie.get("huanbaoqiao_userinfo");
      this.userinfo = userinfo
    }
    window.addEventListener("popstate", this.popstate, false);
  },


  watch: {
    $route: {
      handler: function (val, oldVal) {
        let that = this;
        if (val.path == "/") {
          that.navIndex = 1;
        } else if (val.path == "/") {
          taht.codePop = !that.codePop;
          that.navIndex = 2;
        } else if (val.path == "/my_order") {
          that.navIndex = 3;
        } else if (val.path == "/my_certificate") {
          that.navIndex = 4;
        } else if (val.path == "/info") {
          that.navIndex = 5;
        } else if (val.path == "/help") {
          that.navIndex = 6;
        } else if (val.path == "/contact_us") {
          that.navIndex = 7;
        }else if (val.path == "/about_us") {
          that.navIndex = 8;
        } else if (val.path == "") {
          that.navIndex = 1;
        }
        if (val.name == "/" && oldVal.name == "info") {
          var token = cookie.get("huanbaoqiao_token");
          if (!token) {
            that.is_login = false;
          } else {
            that.is_login = true;
            that.userinfo = cookie.get("huanbaoqiao_userinfo");
          }
        } else {
          var token = cookie.get("huanbaoqiao_token");
          if (!token) {
            that.is_login = false;
          } else {
            that.is_login = true;
            that.userinfo = cookie.get("huanbaoqiao_userinfo");
          }
        }

      },
      // 深度观察监听
      deep: true,
    },
  },

  destroyed() {
    // 销毁vm组件
    // 避免堆栈溢出，多次创建、多次触发
    window.removeEventListener("popstate", this.popstate, false);
  },
  mounted() {
    // 不使用setTimeout无法在created和mounted中获取到this.$route的实际值
    setTimeout(() => {
      const curPath = this.$route.path;
      let that = this;
      that.curPath = curPath 
      if (curPath == "/") {
        that.navIndex = 1;
      } else if (curPath == "/") {
        that.navIndex = 2;
        that.codePop = !that.codePop;
      } else if (curPath == "/my_order" || curPath == "/order_detail") {
        that.navIndex = 3;
      } else if (curPath == "/my_certificate") {
        that.navIndex = 4;
      } else if (curPath == "/info" || curPath == "/certificate" || curPath == "/invoices_status"
        || curPath == "/contract_management" || curPath == "/change_password") {
        that.navIndex = 5;
      } else if (curPath == "/help") {
        that.navIndex = 6;
      } else if (curPath == "/contact_us") {
        that.navIndex = 7;
      } else if (curPath == "/about_us") {
        that.navIndex = 8;
      }
      else if (curPath == "") {
        that.navIndex = 1;
      }
      var token = cookie.get("huanbaoqiao_token");
      if (!token) {
        that.is_login = false;
      } else {
        that.is_login = true;
      }
    }, 600);
  },

  methods: {

    //根据邀请码筛选商品
    invitation() {
      let that = this
				let invitationCode = that.invitationCode
				if (!invitationCode) {
          that.$message.error('请输入邀请码');
					return;
				}
        that.$api.apiGoodsDetail({
					invitationCode: invitationCode,
          forInvitation:'1',
          status:'1'
				}).then((res) => {
        if (res.resCode == 0) {
          if(res.root.length >0){
							that.codePop = false;
              that.invitationCode = '';
              that.canScroll();
              that.$router.push("/goods_detail?id=" + res.root[0].id);
						}else{
              that.$message.error('未找到该邀请码商品'); 
						}
        } else {
          that.$message.error(res.resMsg);
        }
      });
    },


    // 关闭显示弹窗
    closePop() {
      this.codePop = !this.codePop;
    },

    //切换tab
    jumpType(type) {
      this.navIndex = type;
      if (type == 1) {
        this.$router.push("/");
      } else if (type == 2) {
        this.codePop = true;
        // this.$router.push("/");
      } else if (type == 3) {
        this.$router.push("/my_order");
      } else if (type == 4) {
        this.$router.push("/my_certificate");
      } else if (type == 5) {
        this.$router.push("/info");
      } else if (type == 6) {
        this.$router.push("/help");
      } else if (type == 7) {
        this.$router.push("/contact_us");
      } else if (type == 8) {
        this.$router.push("/about_us");
      } else {
        this.$router.push("");
        this.navIndex = 1;
      }
    },


    //跳转登录
    jumpLogin(type) {
      if (type == 1) {
        this.$router.push("/login");
      } else {
        this.$router.push("/register");
      }
    },

    showQuitPop() {
      this.showQuit = !this.showQuit;
    },
    quitLogin() {
      let that = this;
      MessageBox.confirm("确定要退出嘛?", "提示", { type: "warning" })
        .then((res) => {
          // 判断 是否是 确认
          if (res == "confirm") {
            // 清除token
            localStorage.removeItem("huanbaoqiao_token");
            cookie.remove("huanbaoqiao_token");
            cookie.remove("huanbaoqiao_userinfo");
            that.userinfo = null
            that.is_login = !that.is_login;
            this.$router.push("/");
          }
        })
        .catch((err) => {
          //    console.log(err);
        });
    }
  },
};
</script>

