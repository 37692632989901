import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  // 首页
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/index.vue')
  },
  // 帮助
  {
    path: '/help',
    name: 'Help',
    component: () => import('../views/help.vue')
  },

  // 登录
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login.vue')
  },
  // 注册
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/register.vue')
  },

  // 关于我们
  {
    path: '/about_us',
    name: 'About_us',
    component: () => import('../views/about_us.vue')
  },
  // 我的证书
  {
    path: '/my_certificate',
    name: 'My_certificate',
    component: () => import('../views/my_certificate.vue')
  },
  // 我的订单
  {
    path: '/my_order',
    name: 'My_order',
    component: () => import('../views/my_order.vue')
  },
  // 提交订单
  {
    path: '/submit_order',
    name: 'Submit_order',
    component: () => import('../views/submit_order.vue')
  },
  // 订单详情
  {
    path: '/order_detail',
    name: 'Order_detail',
    component: () => import('../views/order_detail.vue')
  },
  // 填写信息
  {
    path: '/fillin_info/:goods_id?/:goods_num?',
    name: 'Fillin_info',
    component: () => import('../views/fillin_info.vue')
  },
	// mao add 240327
	  // 填写信息er
  {
    path: '/fillin_info_er/:goods_id?/:goods_num?',
    name: 'Fillin_info_er',
    component: () => import('../views/fillin_info_er.vue')
  },
	  // add end
  // 商品详情
  {
    path: '/goods_detail',
    name: 'Goods_detail',
    component: () => import('../views/goods_detail.vue')
  },
  // 联系我们
  {
    path: '/contact_us',
    name: 'Contact_us',
    component: () => import('../views/contact_us.vue')
  },
  // 个人中心-基本信息
  {
    path: '/info',
    name: 'Info',
    component: () => import('../views/info.vue')
  },
  // 个人中心-证书抬头
  {
    path: '/certificate',
    name: 'Certificate',
    component: () => import('../views/certificate.vue')
  },
  //个人中心-发票管理-待开票已开票
  {
    path: '/invoices_status',
    name: 'Invoices_status',
    component: () => import('../views/invoices_status.vue')
  },
  //个人中心-发票管理-申请开票
  {
    path: '/invoices_apply',
    name: 'Invoices_apply',
    component: () => import('../views/invoices_apply.vue')
  },
  // 个人中心-发票管理-发票信息维护
  {
    path: '/invoices_maintain',
    name: 'Invoices_maintain',
    component: () => import('../views/invoices_maintain.vue')
  },
  // 个人中心-发票管理-纸质物流
  {
    path: '/invoices_logistics',
    name: 'Invoices_logistics',
    component: () => import('../views/invoices_logistics.vue')
  },
  // 个人中心-合同管理
  {
    path: '/contract_management',
    name: 'Contract_management',
    component: () => import('../views/contract_management.vue')
  },
    // 个人中心-关于我们
    {
      path: '/per_about',
      name: 'Per_about',
      component: () => import('../views/per_about.vue')
    },
    // 忘记密码
    {
      path: '/password',
      name: 'Password',
      component: () => import('../views/password.vue')
    },
     // 隐私协议
     {
      path: '/agreement',
      name: 'Agreement',
      component: () => import('../views/agreement.vue')
    },
   // 证书案例
   {
    path: '/certificate_more',
    name: 'Certificate_more',
    component: () => import('../views/certificate_more.vue')
  },
  // 修改密码
  {
    path: '/change_password',
    name: 'Change_password',
    component: () => import('../views/change_password.vue')
  },
    // 修改手机
    {
      path: '/change_phone',
      name: 'Change_phone',
      component: () => import('../views/change_phone.vue')
    },
     // 发票详情
     {
      path: '/invoices_detail',
      name: 'Invoices_detail',
      component: () => import('../views/invoices_detail.vue')
    },
    // 开票信息
    {
      path: '/maintain_list',
      name: 'Maintain_list',
      component: () => import('../views/maintain_list.vue')
    },
    // 收件地址
    {
      path: '/address_list',
      name: 'Address_list',
      component: () => import('../views/address_list.vue')
    },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
