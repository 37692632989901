import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '../src/assets/css/style.css'
import '../src/assets/css/reset.css'
import Header from '/src/components/header.vue'
import Footer from '/src/components/footer.vue'
import Popup from '/src/components/popup.vue'
import * as api from './request/api.js';// 导入api接口
import ElementUI from 'element-ui';// 消息提示
import 'element-ui/lib/theme-chalk/index.css';
import VueCookies from 'vue-cookies';// token

import hevueImgPreview from 'hevue-img-preview'
Vue.use(hevueImgPreview)
// main.js
import htmlToPdf from '/src/assets/js/htmlToPdf'
Vue.use(htmlToPdf)

router.afterEach((to,from,next)=>{
  window.scrollTo(0,0)
})

// 全局变量引入global
import global from "@/global/global.js"
Vue.prototype.global = global

Vue.config.productionTip = false
Vue.prototype.$api = api;//挂载
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
Vue.use(ElementUI);
Vue.use(VueCookies)
Vue.component('Header',Header)
Vue.component('Footer',Footer)
Vue.component('Popup',Popup)


//弹出框禁止滑动
Vue.prototype.stopScroll = function () {
  var mo = function (e) { e.preventDefault() }
  document.body.style.overflow = 'hidden'
  document.addEventListener('touchmove', mo, false)// 禁止页面滑动
}
 
//弹出框可以滑动
Vue.prototype.canScroll = function () {
  var mo = function (e) {
    e.preventDefault()
  }
  document.body.style.overflow = ''// 出现滚动条
  document.removeEventListener('touchmove', mo, false)
}