<template>
  <div class="footer">
    <div class="m1440">
      <div class="footer_box flex-box flex-col-start">
        <div class="flex-grow-1 colf">
          <div class="fs14 pb10">全国统一服务热线</div>
          <div class="fs28 pb30">021-6246-2036</div>
          <div class="fs12 pb10 flex-box">周一至周五8:30-18:00</div>
          <div class="fs12 pb10 flex-box">business@climatebridge.com</div>
          <div class="fs12 pb10 flex-box">上海市浦东新区福山路33号建工大厦24楼B座</div>
        </div>
        <div class="footer_item">
          <a href="javascript:;" class="pb30 nocursor">首页</a>
          <a href="javascript:;" class="pb30" @click="clickRef(0)">商品展示</a>
          <a href="javascript:;" class="pb30" @click="clickRef(1)">业务流程说明</a>
          <a href="javascript:;" class="pb30" @click="clickRef(2)">常见问题</a>
          <a href="javascript:;" class="pb30" @click="clickRef(3)">证书模板展示</a>
          <!-- <router-link to="/about_us" class="pb15">关于我们</router-link> -->
        </div>
        <!-- <div class="footer_item">
          <a href="javascript:;" class="pb30 nocursor">商品展示</a>
          <router-link to="/" class="pb15">商品展示</router-link>
        </div>
        <div class="footer_item">
          <a href="javascript:;" class="pb30 nocursor">证书示例</a>
          <router-link to="/certificate_more" class="pb15">证书示例列表</router-link>
        </div>
        <div class="footer_item">
          <a href="javascript:;" class="pb30 nocursor">业务流程说明</a>
          <router-link to="/" class="pb15">普通物品流程</router-link>
          <router-link to="/" class="pb15">邀请码物品流程</router-link>
        </div> -->
        <div class="footer_item">
          <a href="javascript:;" class="pb30 nocursor">邀请码</a>
          <a href="javascript:;" class="pb15" @click="closePop">输入邀请码</a>
        </div>
        <div class="footer_item">
          <a href="javascript:;" class="pb30 nocursor">环保桥</a>
          <router-link to="/about_us" class="pb15">关于我们</router-link>
          <router-link to="/contact_us" class="pb15">联系我们</router-link>
          <a href="http://www.climatebridge.com" target="_blank" class="pb15">环保桥官网</a>
        </div>
        <div class="footer_item">
          <a href="javascript:;" class="pb30 nocursor">个人中心</a>
          <a href="javascript:;" class="pb15" @click="jump(1)">订单管理</a>
          <a href="javascript:;" class="pb15" @click="jump(2)">基本信息</a>
          <a href="javascript:;" class="pb15" @click="jump(3)">我的证书</a>
          <a href="javascript:;" class="pb15" @click="jump(4)">发票管理</a>
          <a href="javascript:;" class="pb15" @click="jump(5)">合同管理</a>
        </div>
        <!-- <div class="footer_item">
          <a href="javascript:;" class="pb30">登录/注册</a>
          <a href="javascript:;" class="pb15" @click="jump(6)">登录</a>
          <a href="javascript:;" class="pb15" @click="jump(7)">注册</a>
        </div> -->
        <div class="footer_item">
          <a href="javascript:;" class="pb30">手机端二维码</a>
          <img :src="oss + qr_code" class="img_code" alt="" />
        </div>
      </div>
      <div class="flex-box flex-center copyright_box">
        <!-- <img src="../assets/static/icon/icon_loog_f.png" class="icon_loog_f" alt="" /> -->
        <img src="../assets/static/icon/icon_bitmap.png" class="icon_bitmap" alt="" />
        <div class="pl10">沪公网安备 31011502013476号</div>
        <div class="pl20">Copyright © climatebridge.com All Rights Reserved 沪ICP备15035085号</div>
      </div>
    </div>
    <Popup :visible="codePop" :confirmText="false" :cancleText="false" @close="closePop">
      <div class="code_wrap">
        <img
          src="../assets/static/icon/icon_close.png"
          class="icon_close"
          @click="closePop"
          alt=""
        />
        <div class="fs20 pb30">输入商品邀请码</div>
        <div class="flex-box pb60">
          <div class="fs18 col9">商品邀请码：</div>
          <input type="text" v-model="invitationCode" class="code_input" />
        </div>
        <div class="flex-box flex-end cancel_sure">
          <div @click="closePop">取消</div>
          <div @click="invitation">确定</div>
        </div>
      </div>
    </Popup>
  </div>
</template>
<script>
import cookie from "vue-cookies";
export default {
  name: "Footer",
  data() {
    return {
      codePop: false,
      invitationCode: "",
      qr_code: "",
      oss: "",
    };
  },

  created() {
    let _that = this;
    _that.oss = _that.global.uploadOss;
    // 获取二维码
    _that.get_qr_code();
  },

  methods: {
    clickRef(index) /* 点击按钮触发 */ {
      if (window.location.href !== window.location.origin) {
        this.$router.push("/");
      }
      setTimeout(() => {
        document.getElementById(`conRef${index}`).scrollIntoView({ behavior: "smooth" });
      }, 400);
    },
    jump(index) {
      let userinfo = cookie.get("huanbaoqiao_userinfo");
      var token = cookie.get("huanbaoqiao_token");
      if (index == 6 || index == 7) {
        if (index == 6) {
          this.$router.push("/login");
        } else {
          this.$router.push("/register");
        }
      } else {
        if (!userinfo || !token) {
          this.$message.error("请先登录");
          return;
        }
        if (index == 1) {
          this.$router.push("/my_order");
        } else if (index == 2) {
          this.$router.push("/info");
        } else if (index == 3) {
          this.$router.push("/my_certificate");
        } else if (index == 4) {
          this.$router.push("/invoices_status");
        } else if (index == 5) {
          this.$router.push("/contract_management");
        }
      }
    },

    //根据邀请码筛选商品
    invitation() {
      let that = this;
      let invitationCode = that.invitationCode;
      if (!invitationCode) {
        that.$message.error("请输入邀请码");
        return;
      }
      that.$api
        .apiGoodsDetail({
          invitationCode: invitationCode,
          forInvitation:'1',
          status:'1'
        })
        .then((res) => {
          if (res.resCode == 0) {
            if (res.root.length > 0) {
              that.codePop = false;
              that.$router.push("/goods_detail?id=" + res.root[0].id);
            } else {
              that.$message.error("未找到该邀请码商品");
            }
          } else {
            that.$message.error(res.resMsg);
          }
        });
    },

    get_qr_code() {
      this.$api.apiIndexBanner({ contentKeys: "QR_CODE" }).then((data_res) => {
        //console.log(data_res)
        if (data_res.resCode == 0) {
          let data = data_res.root[0];
          if (data && data.pictureUrl) {
            var img = data.pictureUrl.split(",");
            this.qr_code = img[0];
          }
        } else {
          this.$message.error(data_res.resMsg);
        }
      });
    },

    // 关闭显示弹窗
    closePop() {
      this.codePop = !this.codePop;
    },
  },
};
</script>
