<template>
  <div id="app">
   
    <router-view />
  </div>
</template>

<script>
export default {
  // created() {
  //   let flag = navigator.userAgent.match(
	// 						/(phone|pad|pod|iPhone|iPod|ios|IOS|android|webos|iphone|ipad|ipod|blackberry|iemobile|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
	// 						);
  //             let href = window.location.href;
  //           if(flag && href.indexOf('http://mall.climatebridge.com/mobile') ===-1){
  //                 window.location.replace("http://mall.climatebridge.com/mobile")
  //           }else if(!flag && href.indexOf('http://mall.climatebridge.com/mobile') !==-1){
  //               window.location.replace("http://mall.climatebridge.com/mobile")
  //           }
  //   }
}

</script>



